
import { Poppins, Quicksand } from "next/font/google";
import Image from "next/image";
import Link from "next/link";
import React, { Component } from "react";
const extraquickSand = Quicksand({ weight: "500", subsets: ["latin"] });
const quicksand = Quicksand({ weight: "400", subsets: ["latin"] });
const popins =Poppins({weight:"400",subsets:['latin']})
export default class CourseListed extends Component {
    constructor(props) {
        super(props);
        this.state = {
          currentPage: 1,
          itemsPerPage: 6, // Adjust the number of items per page as needed
          searchTerm:'',
        };
      }

      handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value, currentPage: 1 });
      };
 
      componentDidMount() {
        const storedPage = localStorage.getItem("currentPage");
        if (storedPage) {
          this.setState({ currentPage: parseInt(storedPage) });
        }
        else{
          localStorage.removeItem("currentPage")
        }
      }
      // componentDidUpdate(prevProps, prevState){
     
      //   if (prevState.currentPage !== this.state.currentPage) {
      //     localStorage.setItem("currentPage", this.state.currentPage);
      //   }
      //   else{
      //     localStorage.removeItem("currentPage")
      //   }
      // }
      componentDidUpdate(prevProps, prevState) {
        // // Reset current page if category changes
        if (prevProps.CourseListdata !== this.props.CourseListdata) {
          this.setState({ currentPage: 1 });
        }
   
        // Update local storage for current page
        if (prevState.currentPage !== this.state.currentPage) {
          localStorage.setItem("currentPage", this.state.currentPage);
        }
      }

      componentWillUnmount(e) {
       
          localStorage.removeItem("currentPage");
        
      }
   
      filterData =()=>{
        const {CourseListdata}=this.props;
        const {searchTerm}=this.state;
      return CourseListdata.filter(item =>
        item.courseName.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
      }
      handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
      };
     
 
  render() {
   
const {hideTitle, hidePagination,hideSearchBar,style}=this.props
const { currentPage, itemsPerPage } = this.state;
const filteredData= this.filterData();
 
    // Calculate the index range of items to display for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
 
    return (
      <section className={`${this.props.pdt}`} >
        <div className="">
          {/* Course Index Page   {hideTitle ? null : ()} title hiding */}
        {hideTitle ? null : (
        <div className="df fjc">
         <div className="top-cate mt40 mmt0 mmb20 df fac fjse">
           <Image
             className="circle pdt3"
             src={"/home/round-img.webp"}
             alt="Circle-Img"
             width={"20"}
             height={"20"}
             loading="lazy"
           />
           <h4 className={`cate-tit-fs ${popins.className}`} >Our Courses</h4>
           <Image
                className="img pdt5"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
         </div>
       {/* <div className="df fac mdn" style={{ marginLeft: "auto" }}><Link href={"/course/"}><p className="mt40 ">View All Courses</p></Link></div>*/}
       </div> )}
       
{/* Top Search Bar */}
 
{hideSearchBar ? null : (
<div className='df fjfe mdf mfdc mfac tpdt20'>
 
    <div className="ml229 mml0">
    <div className='df fac fjsb search-bar pdlr10 pdlr0 mpd10' >
 
    <div className=' search-icon '>
      <Image src={"/blog-imgs/blog-cards-img/search-icon.webp"} alt='Search-Icon' width={"32"} height={"32"}/>
    </div>
      <input className={`search-input fs-16 pos-f ${popins.className}`}  type='search'  value={this.state.searchTerm} onChange={this.handleSearchChange}/>
     <div>
     <button className={`search-btn ylw-clr mdn fs-16 ${quicksand.className}`}>Go</button>
     </div>
    </div>
    </div>
  </div>
)}
          <div  className={`df  fw mdf mfjc mfac gap10 pdl20 mpdl0 pdt30 pdb30 mpdt10`}>
            {currentItems.map((items) => {
              return (
             <div className='df fjsb'>
     
     <div className='card-box'>
        <div className='pos-r'>
      <div className='pd8'>
      <Link href={`/training/${items.path}-training`}>
        <Image className="img-radius mdn" src={`/course-img/bg-img/${items.path}.webp`} quality={100} width={"384"} height={"280"} alt={`${items.courseName}-Iamge`} loading='lazy'/>
       </Link>
       <Link href={`/training/${items.path}-training`}>
        <Image className="img-radius dn mdb" src={`/course-img/bg-img/${items.path}.webp`} quality={100} width={"274"} height={"180"} alt={`${items.courseName}-Iamge`} loading='lazy'/>
       </Link>
      </div>
      <div className="img-box df fjc fac">
      <Image src={`/course-img/card-img/${items.path}.webp`} quality={100} alt={items.courseName} width={items.width} height={items.height} loading="lazy" className="mdn" />
      <Image
 
src={`/course-img/card-img/${items.path}.webp`}
 
alt={items.courseName}
 
width={items.mwidth}
 
height={items.mheight}
 
loading="lazy"
 
className="dn mdb"
 
/>
     </div>
     {items.offer && (
     <div className='offer-box'>  <h6 className={`tac fs-19 ${popins.className}`}>{items.offer}</h6></div>)}
     </div>
 
<div className=''>
    <div className='df fdc fac'>
 <Link href={`/training/${items.path}-training`}>    
<p className={`fs-24 mfs-19 tac`}>{items.courseName}</p>
</Link>
 
<div className='line mt16 mmt10'></div>
</div>
 
<div className='df fjse pdt16 mpdt10'>
 
<div className='df'>
 
<div className='df fjc fac'>
  <Image className="mdn" src={"/course-img/CourseListed-img/duration-img.svg"} width={"24"} height={"24"} alt='Duration-img' loading='lazy'/>
  <Image className="dn mdb" src={"/course-img/CourseListed-img/duration-img.svg"} width={"18"} height={"18"} alt='Duration-img' loading='lazy'/>  
 </div>
<div className='pdl16'>
<p className={`fs-12 dim-clr ${quicksand.className}`}>Hours</p>
<p className={`fs-12 pdt5 ${quicksand.className}`}>{items.courseHours}</p>
 
</div>
 
 
 
</div>    
 
<div className='df'>
 
<div className='df fjc fac'>
  <Image className="mdn" src={"/course-img/CourseListed-img/enroll-img.svg"} width={"24"} height={"24"} alt='Duration-img' loading='lazy'/>  
  <Image className="dn mdb" src={"/course-img/CourseListed-img/enroll-img.svg"} width={"18"} height={"18"} alt='Duration-img' loading='lazy'/>
   </div>
<div className='pdl16'>
<p className={`fs-12 dim-clr ${quicksand.className}`}>Enrolled</p>
<p className={`fs-12 pdt5 ${quicksand.className}`}>{items.courseEnrolled}</p>
 
</div>
 
 
 
</div>
 
<div className='df'>
 
<div className='df fjc fac'>
  <Image className="mdn" src={"/course-img/CourseListed-img/class-img.svg"} width={"24"} height={"24"} alt='Duration-img' loading='lazy'/>  
  <Image className="dn mdb" src={"/course-img/CourseListed-img/class-img.svg"} width={"18"} height={"18"} alt='Duration-img' loading='lazy'/>
   </div>
<div className='pdl16'>
<p className={`fs-12 dim-clr ${quicksand.className}`}>Class type</p>
<p className={`fs-12 pdt5 ${quicksand.className}`}>Online | Offline</p>
 
</div>
 
 
 
</div>
 
 
</div>
 
<div className='df fjc fac'>
<div className='line mt16 mmt10'></div>
 
</div>
<div className="df fjse mt16 mmt10">
                      <div className="df fdc gap10 mgap5">
                        <div className="df gap10 mgap5">
                        <div className="mdn">
                          <Image
                            src={"/course-img/tick-icon.webp"}
                            alt="Tick-Icon"
                            width={"16"}
                            height={"16"}
                            loading="lazy"
                          /></div>
                          <div className="dn mdb">
                          <Image
                            src={"/course-img/tick-icon.webp"}
                            alt="Tick-Icon"
                            width={"12"}
                            height={"12"}
                            loading="lazy"
                          /></div>
                          <p
                            className={`cb1 fs12 lnh15 ls04 cs-head-fs ${quicksand.className}`}
                          >
                            1-1 Classes
                          </p>
                        </div>
                        <div className="df gap10 mgap5">
                        <div className="mdn">
                          <Image
                            src={"/course-img/tick-icon.webp"}
                            alt="Tick-Icon"
                            width={"16"}
                            height={"16"}
                            loading="lazy"
                          /></div>
                          <div className="dn mdb">
                          <Image
                            src={"/course-img/tick-icon.webp"}
                            alt="Tick-Icon"
                            width={"12"}
                            height={"12"}
                            loading="lazy"
                          /></div>
                          <p
                            className={`cb1 fs12 lnh15 ls04 cs-head-fs ${quicksand.className}`}
                          >
                            1-Many Classes
                          </p>
                        </div>
                        <div className="df gap10 mgap5">
                          <div className="mdn">
                          <Image
                            src={"/course-img/tick-icon.webp"}
                            alt="Tick-Icon"
                            width={"16"}
                            height={"16"}
                            loading="lazy"
                          /></div>
                          <div className="dn mdb">
                          <Image
                            src={"/course-img/tick-icon.webp"}
                            alt="Tick-Icon"
                            width={"12"}
                            height={"12"}
                            loading="lazy"
                          /></div>
                          <p
                            className={`cb1 fs12 lnh15 ls04 cs-head-fs ${quicksand.className}`}
                          >
                            Job Support
                          </p>
                        </div>
                      </div>
                      <div className="df fjc fac">
                        <Link href={`/training/${items.path}-training`} >
                        <button className={`btn ${popins.className}`}>
                           Explore
                        </button>
                        </Link>
                      </div>
                </div>
</div>
 
 
    </div>
         
    </div>
              );
            })}
          </div>
          {/*!hidePagination && index page pagination hiding */}
          {filteredData.length > itemsPerPage &&  !hidePagination && (
           
              <div className=" df fac fjc mt100 mb88 mmt40 mmb30 gap10">
             
{/* <button className="prev-btn" onClick={() => this.setState({ currentPage: currentPage - 1 })} disabled={currentPage === 1}> */}
 
 
{currentPage >= 1 && (
 
   
<button className="first-btn" onClick={() => this.handlePageChange(1)} disabled={currentPage === 1}>
<Image src={"/course-img/pagination-icons/next-icon.svg"} alt="Prev-Icon" width={"12"} height={"24"} loading="lazy"/>              <Image src={"/course-img/pagination-icons/next-icon.svg"} alt="Prev-Icon" width={"12"} height={"24"} loading="lazy"/>  
</button>
 
)}
<button className="prev-btn" onClick={() => this.handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
<Image src={"/course-img/pagination-icons/next-icon.svg"} alt="Prev-Icon" width={"12"} height={"24"} loading="lazy"/>  
   
         </button>
             
 
 
  {/* {currentPage > 1 && (
    <p className={`fs-19 page ylw-clr df fac fjc ${popins.className}`} onClick={() => this.handlePageChange(currentPage - 1)}>
      {currentPage - 1}
    </p>
  )} */}
 
  <p className={`current-page  fs-19 df fac fjc ${popins.className}`} onClick={() => this.handlePageChange(currentPage)}>
    {currentPage}
  </p>
{/*
  {currentPage < totalPages - 1 && (
    <p className={`fs-19 page ylw-clr df fac fjc ${popins.className}`} onClick={() => this.handlePageChange(currentPage + 1)}>
      {currentPage + 1}
    </p>
  )} */}
{/*
  {currentPage < totalPages && (
    <p className={`fs-19 page df fac fjc ${popins.className}`} onClick={() => this.handlePageChange(totalPages)}>
      {totalPages}
    </p>
  )} */}
                {/* <button className="next-btn" onClick={() => this.setState({ currentPage: currentPage + 1 })} disabled={indexOfLastItem >= filteredData.length}> */}
               
                <button className="next-btn" onClick={() => this.handlePageChange(currentPage + 1)} disabled={indexOfLastItem >= filteredData.length}>
                <Image src={"/course-img/pagination-icons/prev-icon.svg"} alt="Prev-Icon" width={"12"} height={"24"} loading="lazy"/>
                </button>
 
{/*
                {currentPage < totalPages && ( */}
 
      <button className="total-btn" onClick={() => this.handlePageChange(totalPages)}  disabled={currentPage >= totalPages}>
                <Image src={"/course-img/pagination-icons/prev-icon.svg"} alt="Prev-Icon" width={"12"} height={"24"} loading="lazy"/>
                <Image src={"/course-img/pagination-icons/prev-icon.svg"} alt="Prev-Icon" width={"12"} height={"24"} loading="lazy"/>
      </button>
   
  {/* )} */}
 
              </div>
            )}
           
        </div>
 
        <style jsx>
          {`
          .dispage{
          color: #a9a9a9;
          }
          .img-radius{
                  border-radius:16px;
          }
              .search-btn{
                width: 72px;
                height:40px;
                border-radius:30px;
                border:none;
                background-color:#FFFFFF;
                text-align: center;
                cursor:pointer;
         
              }
         
              .search-bar{
                width:400px;
                height:58px;
                border-radius: 30px;
                background-color:#FFA900;
              }
              .search-input{
                border:none;
                outline:none;
                background-color: transparent;
                width:auto;
                color:#FFFFFF;
               
              }
              .search-input::-webkit-search-cancel-button{
                display:none;
              }
          .next-btn:disabled,
.prev-btn:disabled {
  opacity: 0.5; /* or any other styling to indicate it's disabled */
  cursor: not-allowed;
}
  .total-btn:disabled,.first-btn:disabled{
    opacity: 0.5; /* or any other styling to indicate it's disabled */
  cursor: not-allowed;
  }
       
          .current-page {
         
            // color: #FFA900; /* Set the text color to black or any color you prefer */
            width:84px;
            height:60px;
            border-right: 1px solid #D3D3D3;
            border-left: 1px solid #D3D3D3;
            text-align: center;
          }
          .page {
 
            width:84px;
            height:60px;
            border-right: 1px solid #D3D3D3;
            border-left: 1px solid #D3D3D3;
            text-align: center;
            cursor: pointer;
          }
          .next-btn{
            background-color: #FFFFFF;
            width:84px;
            height: 60px;
            cursor: pointer;
            color:#FFA900;
            border: none;
            font-size: 24px;
            font-weight: 500;
            border-radius: 5px;
            box-shadow: 0px 4px 16px 0px #FFA90040;
          }
         .first-btn{
             width:84px;
            height: 60px;
            background-color: #FFFFFF;
            border: none;
            cursor: pointer;
            font-size: 24px;
            font-weight: 500;
            color:#FFA900;
            border-radius: 30px 0px 0px 30px;
            box-shadow: 0px 4px 8px 5px #FFA90040;
         
         
         }
          .total-btn{
            background-color: #FFFFFF;
            width:84px;
            height: 60px;
            cursor: pointer;
            color:#FFA900;
            border: none;
            font-size: 24px;
            font-weight: 500;
            border-radius: 0px 30px 30px 0px;
            box-shadow: 0px 4px 16px 0px #FFA90040;
          }
 
          .prev-btn{
            width:84px;
            height: 60px;
            background-color: #FFFFFF;
            border: none;
            cursor: pointer;
            font-size: 24px;
            font-weight: 500;
            color:#FFA900;
            border-radius: 5px;
            box-shadow: 0px 4px 8px 5px #FFA90040;
          }
     
            .fw500 {
              font-weight: 500;
            }
         
           
            .top-cate{
              background-color:#FFFFFF;
              width:322px;
              height:70px;
              border: 1px solid #FDBB39;
              border-radius:46px;
              box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
           
   
             }
             .cate-tit-fs{
              font-size:33px;
              letter-spacing: 0.25px;
              line-height: 41px;
              color:#000000;
             }
             .btn {
              width: 130px;
              height: 40px;
              padding: 8px 24px; /* Assuming this is shorthand for top, right, bottom, left */
              border-radius: 32px;
              gap: 10px;
              background-color: #ffa900;
              cursor: pointer;
              font-size: 16px;
              line-height: 24px;
              letter-spacing:0.5px;
              color:#090909;
              border:none;
              font-weight: 500;
            }
          .line{
              width:384px;
 
              border : 1px solid #FFA90033;
          }
          .card-box{
              width: 400px;
              height: 550px;
              border-radius: 24px;
              background-color: #FFFFFF;
              box-shadow: 0px 8px 16px 0px #00000040;
 
          }
          .img-box {
              width: 106px;
              height: 44px;
              border: 0.5px solid #ffa900;
              border-radius: 10px;
              background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.14),
                  rgba(0, 0, 0, 0.14)
                ),
                linear-gradient(0deg, #ffffff, #ffffff);
                position: absolute;
                top: 8%;
                right: 67%; /* Half of the width */
             
               
            }
         
            .offer-box {
              width: 364px;
              height: 30px;
              border-radius: 8px;
              background-color: #ffa900;
              position: absolute;
              top: 80%;
              left: 50%;
              transform: translateX(-50%);
              overflow: hidden; /* Hide the overflow */
              z-index: 1; /* Ensure this is above other elements */
         
          }
          .offer-box::before {
              content: "";
              position: absolute;
              top: 0px;
              left: 5px;
              width: 30%;
              height: 50%;
              background: rgba(255, 254, 254, 8);
              mix-blend-mode: soft-light;
              transform: rotate(45deg);
              animation: shine 5s infinite; /* Animation */
              z-index:1px;
          }
 
          @keyframes shine {
         
            0% {
              left: 100%; /* Start position on the left */
             
          }
          50% {
              left: -30%; /* End position on the right */
           
          }
          100%{
            left: 100%;
           
 
          }
        }
 
 
          .offer-box h6{
           
              font-size:19px;
              letter-spacing: 0.15px;
              line-height: 27px;
          }
                 
         
 
          @media only screen and (max-width: 1023px){
           
            .card-box{
              width: 290px;
              height: auto;
              border-radius: 18px;
              background-color: #FFFFFF;
              box-shadow: 0px 8px 16px 0px #00000040;
              padding-bottom:10px;
 
          }
          .img-box {
            width: 100px;
            height: 40px;
            border: 0.5px solid #ffa900;
            border-radius: 10px;
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.14),
                rgba(0, 0, 0, 0.14)
              ),
              linear-gradient(0deg, #ffffff, #ffffff);
              position: absolute;
              top: 8%;
              right: 60%; /* Half of the width */
           
             
          }
          .line{
            width:276px;
 
            border : 1px solid #FFA90033;
        }
        .offer-box {
          width: 254px;
          height: 30px;
          border-radius: 8px;
          background-color: #ffa900;
          position: absolute;
          top: 75%;
          left: 50%;
          transform: translateX(-50%);
          overflow: hidden; /* Hide the overflow */
          z-index: 1; /* Ensure this is above other elements */
     
      }
       
            .search-btn{
              width: 42px;
              height:30px;
              border-radius:20px;
              border:none;
              background-color:#FFFFFF;
              text-align: center;
       
            }
     
            .search-bar{
              width:280px;
              height:28px;
              border-radius: 15px;
              background-color:#FFA900;
            }
            .current-page {
         
              // color: #FFA900; /* Set the text color to black or any color you prefer */
              text-align: center;
            }
            .page {
 
              width:44px;
              height:44px;
             border:none;
              text-align: center;
              cursor: pointer;
            }
            .next-btn{
              background-color: #FFFFFF;
              width:44px;
              height: 44px;
              cursor: pointer;
              color:#FFA900;
              border: none;
              font-size: 19px;
              font-weight: 500;
              border-radius: 0px 15px 15px 0px;
              box-shadow: 0px 4px 10px 0px #FFA90040;
           
            }
               .first-btn{
             width:44px;
            height: 40px;
            background-color: #FFFFFF;
            border: none;
            cursor: pointer;
            font-size: 24px;
            font-weight: 500;
            color:#FFA900;
            border-radius: 30px 0px 0px 30px;
            box-shadow: 0px 4px 8px 5px #FFA90040;
         
         
         }
                .total-btn{
            background-color: #FFFFFF;
            width:44px;
            height: 44px;
            cursor: pointer;
            color:#FFA900;
            border: none;
            font-size: 24px;
            font-weight: 500;
            border-radius: 0px 30px 30px 0px;
            box-shadow: 0px 4px 16px 0px #FFA90040;
          }
 
           
            .prev-btn{
              width:44px;
              height: 44px;
              background-color: #FFFFFF;
              border: none;
              cursor: pointer;
              font-size: 19px;
              font-weight: 500;
              color:#FFA900;
              box-shadow: 0px 4px 10px 0px #FFA90040;
            }
       
       
            .cate-tit-fs{
              font-size:20.74px;
              letter-spacing: 0.25px;
              line-height: 25.77px;
              color:#000000;
             }
            .top-cate{
              background-color:#FFFFFF;
              width:230px;
              height:44px;
              border: 0.63px solid #FDBB39;
              border-radius:28.91px;
              box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
             
             }
               
           
           
 
          }
 
          @media only screen and (max-width: 1250px){
         
          .mfac{
          align-items: center;
          justify-content: center;
          }
          .tpdt20{
          padding-top: 20px;
          }
          }
 
 
          `}
        </style>
      </section>
    );
  }
}
 
 